<!--
  PACKAGE_NAME : src/pages/euc/phone/request
  FILE_NAME : supercopy.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 신규장치 그리드 -  착신전환 , 당겨받기 , 녹취옵션 영역은 유지하되 값 공백처리.
-->
<template>
  <div class="container content-themes">
    <div class="page-sub-box clearfix ui-glid-box per33 ui-33" style="padding-bottom: 20px">
      <table class="fl" style="width: 49.5%">
        <thead class="sub_title_txt">
          <tr>
            <td colspan="4" style="padding: 18px 20px">
              <h2 style="width: 200px; height: 30px" class="fl">{{ $_msgContents('UC.WORD.TARGET_DEVICE') }}</h2>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 0">
              <div class="fl">
                <table class="table_form line-bin" style="table-layout: auto; width: 100%; table-layout: fixed">
                  <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 34%" />
                    <col style="width: 15%" />
                    <col style="width: 34%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <label for="label01">{{ $_msgContents('UC.WORD.EXCHANGER') }}</label>
                      </th>
                      <th>{{ getCmNm(deviceInfo.cmname) }}</th>
                      <td colspan="2" rowspan="3" style="padding: 0">
                        <div class="fl" style="width: 100%; text-align: center">
                          <img v-if="deviceImage" :src="deviceImage" alt="Device" />
                          <img
                            v-if="deviceExpImage"
                            :src="deviceExpImage"
                            alt="DeviceExp"
                            style="position: absolute; bottom: 0px"
                            class="fr"
                          />
                          <!-- @click="isOpenModal(true)" -->
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <label for="label02">{{ $_msgContents('UC.WORD.DEVICE_NAME') }}</label>
                      </th>
                      <th>{{ deviceInfo.name }}</th>
                    </tr>

                    <tr>
                      <th scope="row" class="alT">
                        <label for="label03">{{ $_msgContents('UC.WORD.DEVICEPOOL') }}</label>
                      </th>
                      <th>
                        {{ deviceInfo.devicepool }}
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.STATUS') }}</label>
                      </th>
                      <th>{{ deviceInfo.status }}</th>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.SERIAL') }}</label>
                      </th>
                      <th>{{ deviceInfo.serial }}</th>
                    </tr>

                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.PHONEBUTTON') }}</label>
                      </th>
                      <th>
                        {{ deviceInfo.phonetemplatename }}
                      </th>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.SOFTKEY') }}</label>
                      </th>
                      <th>
                        {{ deviceInfo.softkeytemplatename }}
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT"><label for="label04">IP</label></th>
                      <th>{{ deviceInfo.ipaddress }}</th>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('COMPONENTS.REGISTRATION_DATE') }}</label>
                      </th>
                      <th>{{ this.formatDt(deviceRegDt) }}</th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.MODEL') }}</label>
                      </th>
                      <th colspan="3">{{ deviceInfo.model }}</th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('COMPONENTS.DESCRIPTION') }}</label>
                      </th>
                      <th colspan="3">{{ deviceInfo.description }}</th>
                    </tr>
                    <thead class="sub_title_txt">
                      <tr>
                        <td colspan="4" class="border0">
                          <h2 style="width: 200px" class="fl">{{ $_msgContents('UC.WORD.LINE_INFO') }}</h2>
                        </td>
                      </tr>
                    </thead>
                    <tr>
                      <td colspan="4" style="padding: 0">
                        <esp-dx-data-grid
                          :data-grid="getDataGrid('origin')"
                          :ref="getDataGridRef('origin')"
                          :auto-width="true"
                          @row-prepared="onRowPrepared"
                        />
                        <!-- @row-updating="onRowUpdating" -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="fr" style="width: 49.5%">
        <thead class="sub_title_txt">
          <tr>
            <td colspan="3" style="padding: 18px 20px">
              <h2 style="width: 200px; height: 30px" class="fl">{{ $_msgContents('UC.WORD.NEW_DEVICE') }}</h2>
              <DxButton
                @click="onSuperCopy()"
                :text="$_msgContents('UC.WORD.APPLY')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
              />
              <DxButton
                @click="selectData()"
                :text="$_msgContents('UC.WORD.CHECK')"
                :width="80"
                :height="30"
                class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                styling-mode="contained"
                type="default"
              />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="padding: 0">
              <div class="fl">
                <table class="table_form line-bin" style="table-layout: auto; width: 100%; table-layout: fixed">
                  <colgroup>
                    <col style="width: 15%" />
                    <col style="width: 34%" />
                    <col style="width: 15%" />
                    <col style="width: 34%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label01">{{ $_msgContents('UC.WORD.EXCHANGER') }}</label>
                      </th>
                      <th>{{ getCmNm(deviceInfo.cmname) }}</th>
                      <td colspan="2" rowspan="3" style="padding: 0">
                        <div class="fl" style="width: 100%; text-align: center">
                          <img v-if="deviceImage" :src="deviceImage" alt="Device" />
                          <!-- <img
                            v-if="deviceExpImage"
                            :src="deviceExpImage"
                            alt="DeviceExp"
                            class=""
                            style="margin-top: +110px"
                          /> -->
                          <!-- @click="isOpenModal(true)" -->
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <label for="label02">{{ $_msgContents('UC.WORD.DEVICE_NAME') }}</label>
                      </th>
                      <td style="padding: 3px 15px">
                        <DxTextBox
                          v-model="copyname"
                          :placeholder="$_msgContents('UC.MESSAGE.DEVICE_NAME_ENTER')"
                          :styling-mode="config.stylingMode"
                          @value-changed="onChangeInput($event)"
                        />
                      </td>
                    </tr>

                    <tr>
                      <th scope="row" class="alT">
                        <label for="label03">{{ $_msgContents('UC.WORD.DEVICEPOOL') }}</label>
                      </th>
                      <th>
                        {{ deviceInfo.devicepool }}
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.STATUS') }}</label>
                      </th>
                      <th>{{ deviceInfo.status }}</th>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.SERIAL') }}</label>
                      </th>
                      <th>{{ deviceInfo.serial }}</th>
                    </tr>

                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.PHONEBUTTON') }}</label>
                      </th>
                      <th>
                        {{ deviceInfo.phonetemplatename }}
                      </th>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.SOFTKEY') }}</label>
                      </th>
                      <th>
                        {{ deviceInfo.softkeytemplatename }}
                      </th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT"><label for="label04">IP</label></th>
                      <th>{{ deviceInfo.ipaddress }}</th>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('COMPONENTS.REGISTRATION_DATE') }}</label>
                      </th>
                      <th></th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('UC.WORD.MODEL') }}</label>
                      </th>
                      <th colspan="3">{{ deviceInfo.model }}</th>
                    </tr>
                    <tr>
                      <th scope="row" class="alT">
                        <label for="label04">{{ $_msgContents('COMPONENTS.DESCRIPTION') }}</label>
                      </th>
                      <td colspan="3" style="padding: 3px 16px">
                        <DxTextBox
                          v-model="copydescription"
                          :placeholder="$_msgContents('UC.MESSAGE.DESCRIPTION_ENTER')"
                          :styling-mode="config.stylingMode"
                          @value-changed="onChangeInput($event)"
                        />
                      </td>
                    </tr>
                    <thead class="sub_title_txt">
                      <tr>
                        <td colspan="4" class="border0">
                          <h2 style="width: 200px" class="fl">{{ $_msgContents('COMPONENTS.DESCRIPTION') }}</h2>
                        </td>
                      </tr>
                    </thead>
                    <tr>
                      <td colspan="4" style="padding: 0">
                        <esp-dx-data-grid
                          :data-grid="getDataGrid('copy')"
                          :ref="getDataGridRef('copy')"
                          :auto-width="true"
                          @row-updating="onRowUpdating"
                          @row-prepared="onRowPrepared"
                          @cell-prepared="onCellPrepared"
                          @editor-prepared="onEditorPrepared"
                        ></esp-dx-data-grid>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <DxButton
                  @click="goListPage()"
                  :text="this.$_msgContents('UC.WORD.PREPAGE')"
                  :width="80"
                  :height="30"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
                  styling-mode="contained"
                  style="margin-top: 10px"
                  type="default"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import DxButton from 'devextreme-vue/button';
  import PlusIcon from '@/assets/images/write.png';
  import DelIcon from '@/assets/images/del.png';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxTextBox,
      PlusIcon,
      DelIcon,
      DxScrollView,
    },
    props: {},
    watch: {},
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        cmList: [],
        deviceImage: '',
        deviceExpImage: '',
        deviceData: {},
        refsList: [
          { name: 'origin', editBool: false },
          { name: 'copy', editBool: true },
        ],
        updateFlag: false,
        serverInfo: '',
        deviceRegDt: '',
        deviceInfo: {},
        deviceList: [],
        copyList: [],
        rowInfo: {},
        changedDeviceInfo: [],
        recList: [],
        cssList: [],
        copyname: '',
        copydescription: '',
        partitionList: [],
        pickupGroupList: [],
        dataGrid: {},
      };
    },
    computed: {
      /** @description: pageData -> pageSetting apiActionNm 가져오기 */
      getApiActionNm() {
        return this.config.pageSetting.config?.apiActionNm || null;
      },
    },
    methods: {
      getDataGrid(value) {
        return this.dataGrid[`dataGrid_${value}`];
      },
      getDataGridRef(value) {
        return `refDataGrid_${value}`;
      },
      /**@description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.$_setPageSettingConfig();
        this.reqParams = this.$store.getters.getDetailParams;

        for (let i = 0; i < this.refsList.length; i++) {
          this.$set(this.dataGrid, `dataGrid_${this.refsList[i].name}`, {
            refName: `refDataGrid_${this.refsList[i].name}`,
            allowColumnResizing: true, //컬럼 사이즈 허용
            showBorders: false, //border 유무
            showColumnHeaders: true, //컬럼 헤더 유무
            showColumnLines: false, //컬럼 세로선 유무
            showRowLines: true, //컬럼 가로선 유무
            rowAlternationEnabled: false,
            disableTotalCount: true,
            dataSource: [],
            // width:'1000',     // 주석처리시 100%
            // height: '320', // 주석처리시 100%
            height: 'calc(100vh - 650px)', // 주석처리시 100%
            apiActionNm: {
              update: '',
            },
            customEvent: {
              //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
              cellPrepared: this.refsList[i].editBool,
              saving: true,
              rowUpdating: true,
              rowUpdated: true,
              rowPrepared: !this.refsList[i].editBool,
              editorPrepared: this.refsList[i].editBool,
            },
            showActionButtons: {
              excel: false, // 엑셀 버튼
              customButtons: [],
            },
            isDuplicateConfigKey: false, //설정키 중복 체크
            grouping: {
              contextMenuEnabled: false,
              autoExpandAll: false,
              allowCollapsing: true,
              expandMode: 'rowClick', // rowClick or buttonClick
            },
            groupPanel: {
              visible: false,
            },
            columnChooser: {
              enabled: false, // 컬럼 Chooser 버튼 사용유무
            },
            loadPanel: {
              enabled: false, // 로딩바 표시 유무
            },
            sorting: {
              mode: 'multiple', // single multiple
            },
            remoteOperations: {
              // 서버사이드 여부
              filtering: true,
              sorting: true,
              grouping: true,
              paging: true,
            },
            paging: {
              enabled: false,
              // pageSize: 10,
              // pageIndex: 0, // 시작페이지
            },
            pager: {
              visible: false, //페이저 표시 여부
              showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
              allowedPageSizes: [],
              displayMode: 'compact', //표시 모드 : ['full', 'compact']
              showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
              showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
            },
            filterRow: {
              visible: false,
            },
            headerFilter: {
              visible: false,
            },
            editing: {
              allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
              allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
              allowDeleting: false,
              mode: 'cell', //수정 모드: ['row', 'cell', 'batch']
              startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
              selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
            },
            selecting: {
              mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
              selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
              showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            },
            columns: [
              {
                caption: '유형',
                i18n: 'UC.WORD.TYPE',
                dataField: 'field',
                width: 80,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: this.refsList[i].editBool,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowSorting: false,
                allowGrouping: false,
                cellTemplate: async (container, options) => {
                  const imgTag = document.createElement('img');
                  const imgData = this.$_getAttachFileURL(null, options.value);
                  imgTag.setAttribute('src', imgData);
                  container.append(imgTag);
                },
              },
              {
                caption: '전화번호',
                i18n: 'UC.WORD.NUMBER',
                dataField: 'number',
                width: 110,
                height: 40,
                alignment: 'center',
                visible: true,
                allowEditing: this.refsList[i].editBool,
                allowSorting: false,
                // allowEditing: true,
                sortOrder: 'none',
                allowHeaderFiltering: false,
                allowGrouping: false,
                validationRules: [
                  {
                    type: 'custom',
                    validationCallback: function (e) {
                      return e.value === '' || (!isNaN(e.value) && e.value !== null);
                    },
                    message: this.$_msgContents('UC.MESSAGE.NUMBER_ENTER'),
                  },
                ],
              },
              {
                caption: '레이블',
                i18n: 'UC.WORD.LABEL',
                dataField: 'label',
                // width: 100,
                height: 40,
                alignment: 'center', // left center right
                visible: true,
                allowEditing: this.refsList[i].editBool,
                sortOrder: 'none', // acs desc none
                allowSorting: false,
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'left', // left or right
              },
              {
                caption: '파티션',
                i18n: 'UC.WORD.PARTITION',
                dataField: 'routepartitionname',
                // width: 100,
                height: 40,
                alignment: 'center',
                visible: true,
                // allowEditing: this.refsList[i].editBool,
                allowEditing: false,
                sortOrder: 'none',
                allowSorting: false,
                allowHeaderFiltering: false,
                allowGrouping: false,
                calculateDisplayCellValue: rowData => {
                  if (rowData?.routepartitionname) {
                    return rowData.routepartitionname;
                  }
                  if (rowData.routepartitionname == null || rowData.routepartitionname == 'null') {
                    return 'None';
                  }
                  if (rowData.field !== 'LINE') {
                    return '-';
                  }
                },
              },
              {
                caption: '발신검색',
                i18n: 'UC.WORD.CSS',
                dataField: 'callingsearchspacename',
                // width: 120,
                height: 40,
                alignment: 'center', // left center right
                visible: true,
                // allowEditing: this.refsList[i].editBool,
                allowEditing: false,
                sortOrder: 'none', // acs desc none
                allowSorting: false,
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'left', // left or right
                calculateDisplayCellValue: rowData => {
                  if (rowData?.callingsearchspacename) {
                    return rowData.callingsearchspacename;
                  }
                  if (rowData.callingsearchspacename == null || rowData.callingsearchspacename == 'null') {
                    return 'None';
                  }
                  if (rowData.field !== 'LINE') {
                    return '-';
                  }
                },
              },

              {
                caption: '착신전환',
                i18n: 'UC.WORD.FORWARDNO',
                dataField: 'forwardno',
                // width: 120,
                height: 40,
                alignment: 'center', // left center right
                visible: true,
                allowEditing: false,
                allowUpdating: true,
                sortOrder: 'none', // acs desc none
                allowSorting: false,
                allowHeaderFiltering: false,
                fixed: false, // 컬럼 fix 시 사용
                fixedPosition: 'left', // left or right
                cellTemplate: (container, options) => {
                  let value = '';
                  if (this.refsList[i].name === 'origin') {
                    if (options.value == null) {
                      value = '';
                    } else {
                      value = options.value;
                    }
                  }
                  container.append(value);
                },
              },
              {
                caption: '당겨받기',
                i18n: 'UC.WORD.PICKUPGROUP',
                dataField: 'pickupgroupname',
                // width: 120,
                height: 40,
                alignment: 'center',
                visible: true,
                // allowEditing: this.refsList[i].editBool,
                allowEditing: false,
                sortOrder: 'none',
                allowSorting: false,
                allowHeaderFiltering: false,
                allowGrouping: false,
                cellTemplate: (container, options) => {
                  let value = '';
                  if (this.refsList[i].name === 'origin') {
                    for (let i = 0; i < this.pickupGroupList.length; i++) {
                      if (this.pickupGroupList[i].name === options.value) {
                        value = this.pickupGroupList[i].description;
                      }
                    }
                  }
                  container.append(value);
                },
              },
              {
                caption: '녹취',
                i18n: 'UC.WORD.REC',
                dataField: 'recprofile',
                // width: 120,
                height: 40,
                alignment: 'center',
                visible: true,
                // allowEditing: this.refsList[i].editBool,
                allowEditing: false,
                sortOrder: 'none',
                allowSorting: false,
                allowHeaderFiltering: false,
                allowExporing: true,
                fixed: false,
                fixedPosition: 'left',
                cellTemplate: (container, options) => {
                  let value = '';
                  //   if (this.refsList[i].name === 'origin') {
                  if (options.value) {
                    for (let i = 0; i < this.recList.length; i++) {
                      if (this.recList[i].recprofile.toUpperCase() === options.value.toUpperCase()) {
                        value = this.recList[i].recname;
                      }
                    }
                  }

                  //   }
                  container.append(value);
                },
              },
            ],
          });
        }
        // }
      },

      /** @description: 단말장치 데이터 상세 조회 메서드 */
      async selectData() {
        if (!this.reqParams) {
          this.$_goPrePage();
          return;
        }

        const { deviceData } = this.reqParams;

        let params = {
          mac: deviceData.name,
          cmNm: deviceData.cmNm,
          loginId: this.$store.getters.getLoginId,
          type: 'MAC',
        };
        const payload = {
          actionname: 'EUC_DEVICE_CISCO_DETAIL',
          data: params,
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          const deviceInfo = res.data.data[0];
          this.deviceRegDt = deviceData.regDt;

          if (deviceInfo.LIST.length > 0) {
            this.deviceInfo = deviceInfo.LIST[0];
            this.deviceList = deviceInfo.LIST[0].devicelist;
            this.copyList = deviceInfo.LIST[0].devicelist;
          } else {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA'));
            await this.$_goPrePage();
            return;
          }
          if (this.deviceInfo?.model) {
            this.deviceImage = this.$_getAttachFileURL(null, this.deviceInfo.model);
          }
          if (this.deviceInfo.addonmodules) {
            this.deviceExpImage = this.$_getAttachFileURL(null, this.deviceInfo.addonmodules);
          } else {
            this.deviceExpImage = '';
          }
          this.copyname = this.deviceInfo.name;
          this.copydescription = this.deviceInfo.description;
          this.dataGrid[`dataGrid_origin`].dataSource = this.deviceList;
          this.dataGrid[`dataGrid_copy`].dataSource = this.copyList;
        } else {
          await this.$_Msg(res.data.header.resMsg);
          await this.$_goPrePage();
        }
      },

      /**@description 그리드 customEvent @onRowUpdating*/
      onRowUpdating(e) {
        let column = Object.keys(e.newData)[0];
        let value = e.newData[column];
        let number = e.oldData.number;
        let newRow = { column: column, value: value, number: number };
        const existingRow = this.changedDeviceInfo.find(row => row.column === column && row.number === number);

        if (existingRow) {
          Object.assign(existingRow, newRow);
        } else {
          this.changedDeviceInfo.push(newRow);
        }
      },
      /** @description  단말기 장치복사 */
      async onSuperCopy() {
        const targetMac = this.deviceInfo.name;
        const mac = this.copyname;

        // if (mac == targetMac) {
        //   // this.$_Msg('장치이름은 같을 수 없습니다. 변경후 다시 시도해주세요.');
        //   await this.$_Msg(this.$_msgContents('UC.MESSAGE.CHECK_MAC_ADDR'));
        //   return false;
        // }
        let refs = this.getDataGridRef('copy');
        if (this.$refs[refs].hasEditData()) {
          await this.$_Msg('수정중인 항목이 있습니다.');
          return false;
        }

        const gridData = this.$refs[this.getDataGridRef('copy')].getItems;
        const parseData = [];
        for (let i = 0; i < gridData.length; i++) {
          let list = {};
          // list.INDEX = gridData[i].index;
          list.INDEX = gridData[i].seq;

          //등록할 내선번호 없을경우 제외.
          if (gridData[i].number == '' || gridData[i].number == null) {
            continue;
          }

          list.NUMBER = gridData[i].number;
          if (gridData[i].label == '' || gridData[i].label == null) {
            list.LABEL = gridData[i].number;
          } else {
            list.LABEL = gridData[i].label;
          }
          parseData.push(list);
        }

        const params = {
          cmNm: this.deviceInfo.cmname,
          mac: mac,
          targetMac: targetMac,
          description: this.copydescription,
          list: JSON.stringify(parseData),
          loginId: this.$store.getters.getLoginId,
        };
        const payload = {
          actionname: 'EUC_DEVICE_SUPERCOPY',
          data: params,
          loading: true,
        };

        let confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DUPLICATE_SELECTED');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }

        const res = await this.CALL_EUC_API(payload);

        if (isSuccess(res)) {
          await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          await this.$_goPrePage();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
      /**@description : 설명 입력창 onChange이벤트*/
      onChangeInput(event) {},

      /** @description : 날짜 형식 변환 메서드 */
      formatDt(date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },
      /**@description 교환기 목록 가져오는 메서드*/
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.setCmName(res);
        }
      },
      /**@description 교환기 목록에서 불필요한 항목 제거 */
      setCmName(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          this.cmList = res.data.data;
          // FIXME 2024-04-11 jhsim cepm에서 cmNm, cmDescription을 같이주면 삭제
          for (let i = 0; i < this.cmList.length; i++) {
            delete this.cmList[i].id;
            delete this.cmList[i].cmIp;
            delete this.cmList[i].passwd;
            delete this.cmList[i].pinPasswd;
            delete this.cmList[i].regDt;
            delete this.cmList[i].siteId;
            delete this.cmList[i].tenantId;
            delete this.cmList[i].userId;
            delete this.cmList[i].vender;
            delete this.cmList[i].viewCd;
          }
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /**@description IPT CODE 가져오는 메서드 */
      async selectCodeList(sort = 'codeKey,codeOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CODE_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.recList = await this.setRecList(res);
        }
      },
      /**@description		코드에서 녹취 항목만 저장하는 함수
       * @param 	{Array}	코드리스트
       * @returns {Array} 녹취리스트
       */
      setRecList(res) {
        const length = res.data.data?.length;
        if (length > 0) {
          const codeList = res.data.data;
          const recList = [];

          for (let i = 0; i < codeList.length; i++) {
            let list = {
              recprofile: '',
              recflag: '',
              recname: '',
              recmediasource: '',
              ord: '',
            };
            if (codeList[i].codeKey === 'recprofile' && codeList[i].parentId != null) {
              list.recprofile = codeList[i].codeValue;
              list.recname = codeList[i].codeNm;
              list.ord = codeList[i].codeOrd;
              recList.push(list);
            }
          }

          for (let i = 0; i < codeList.length; i++) {
            for (let j = 0; j < recList.length; j++) {
              if (codeList[i].codeKey === 'recflag' && codeList[i].parentId != null) {
                if (recList[j].ord === codeList[i].codeOrd) {
                  recList[j].recflag = codeList[i].codeValue;
                }
              }
              if (codeList[i].codeKey === 'recmediasource' && codeList[i].parentId != null) {
                if (recList[j].ord === codeList[i].codeOrd) {
                  recList[j].recmediasource = codeList[i].codeValue;
                }
              }
            }
          }
          return recList;
        }
      },
      /**@description 파티션 목록 가져오는 메서드 */
      async selectPartitionList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_PARTITION_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.partitionList = res.data.data;
          }
        }
      },
      /**@description 발신검색 목록 가져오는 메서드 */
      async selectCssList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CSS_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.cssList = res.data.data;
          }
        }
      },

      /**@description 당겨받기그룹 목록 가져오는 메서드 */
      async selectPickupGroupList(sort = 'regDt,id') {
        let params = { sort: sort, viewFl: 'Y' };

        const payload = {
          actionname: 'EUC_PICKUPGROUP_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.pickupGroupList = res.data.data;
          }
        }
      },
      /**@description 그리드 셀 X버튼 클릭시 셀 데이터 삭제 */
      // setCellDelete(row, type) {
      // 	this.$refs[this.getDataGridRef(type)].getGridInstance.cellValue(row.rowIndex, row.columnIndex, '');
      // 	this.$refs[this.getDataGridRef(type)].getGridInstance.saveEditData();
      // },
      /**@description grid row 생성전 호출되는 메서드 */
      onRowPrepared(e) {
        if (e.rowType === 'data') {
          e.rowElement.style.backgroundColor = '#f9f9f9';
        }
      },
      /** @description : 그리드 셀 관련 준비 커스텀 이벤트 */
      onCellPrepared(e) {
        if (e.rowType === 'data') {
          if (e.columnIndex === 0 || e.columnIndex > 2 || e.data.field === 'SURL') {
            e.cellElement.style.backgroundColor = '#f9f9f9';
          }

          if (e.data.field !== 'LINE') {
            if (e.data.field === 'SURL') {
              e.cellElement.style.backgroundColor = '#f9f9f9';
            } else if (e.data.field === 'SD' || e.data.field === 'BLF') {
              if (e.columnIndex > 3) {
                e.cellElement.style.backgroundColor = '#f9f9f9';
              }
            }
          }
        }
      },
      /** @description 그리드 셀 편집기가 생성된 후 실행되는 함수 */
      onEditorPrepared(e) {
        if (e.row.cells[0].value === 'SURL') {
          e.component.cancelEditData();
        }

        if (e.row.cells[0].value === 'SD' || e.row.cells[0].value === 'BLF') {
          // if (e.index > 2) {
          e.component.cancelEditData();
          // }
        }
      },
      /** @description 이전페이지 이동 함수 */
      goListPage() {
        if (this.$store.getters?.getPreviousPath) {
          this.$_goPrePage();
        } else {
          this.$router.push('/euc/device');
        }
      },
      /**@description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountedData() {
        await this.selectCmList();
        await this.selectCodeList();
        // await this.selectPartitionList();
        // await this.selectCssList();
        await this.selectPickupGroupList();
        await this.selectData();
      },
      /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
      destroyedData() {
        this.$store.commit('setDetailParams', null);
      },
    },
    async created() {
      await this.createdData();
    },
    async mounted() {
      await this.mountedData();
    },
    destroyed() {
      this.destroyedData();
    },
  };
</script>
<style scoped>
  .device-img > img {
    align-self: center;
  }

  .fl {
    border-right: 0 !important;
  }

  tbody {
    display: table-row-group;
    vertical-align: top;
  }

  .table_form tbody th {
    border: 1px solid #ddd;
    padding: 10px 20px;
  }
  .table_form tbody td {
    border: 1px solid #ddd;
    padding: 10px 20px;
  }
  .border0 td {
    border: 0px;
    /* padding: 18px 20px; */
  }
</style>
